import React from "react"
import { graphql } from 'gatsby'
import BlogTile from "../components/sections/blog-tile"
import BlogHero from "../components/sections/blog-hero"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import styled from "styled-components"
import { Container } from "../components/global"

import Footer from "../components/sections/footer"
import CookieConsent from "react-cookie-consent";

import { useIntl } from "gatsby-plugin-react-intl"

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {

  const intl = useIntl();
  const HeroTile = edges
    .filter(edge => edge.node.frontmatter.lang.includes(intl.locale))
    .slice(0, 1).map(edge => <BlogHero key={edge.node.id} post={edge.node} />)
  const BlogTiles = edges
    .filter(edge => edge.node.frontmatter.lang.includes(intl.locale))
    .slice(1)
    .map(edge => <BlogTile key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Navigation />
      <PostsWrapper>
        <Container>
          <Hero>
            {HeroTile}
          </Hero>
          <Tiles>
            {BlogTiles}
          </Tiles>
        </Container>
      </PostsWrapper>
      <Footer />
      <CookieConsent
        disableStyles={true}
        onAccept={() => {
          window.trackHotjar();
          window.trackGoogleAnalytics();
        }}
        buttonText={intl.formatMessage({ id: "cookie_consent_accept_button" })}
        cookieName="gdpr-analytics-enabled"
        containerClasses="bottom-wrapper root-style container"
        buttonClasses="consent-button"
        contentClasses="consent-content"
        expires={150}
      >
        {intl.formatMessage({ id: "cookie" })} <br /><a style={{color: "black"}} href="/privacy-policy">{intl.formatMessage({ id: "read_more" })} </a>
      </CookieConsent>
    </Layout>)
}

export default BlogPage


export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {blogPost: {eq: true}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date
            slug
            title
            author
            authorTitle
            lang
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            authorImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const PostsWrapper = styled.header`
    background-color: ${props => props.theme.color.background.light};
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
    @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const Hero = styled.div`
  padding-top: 200px;
  display: grid;
  grid-template-columns: 1frs;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  } 
`
const Tiles = styled.div`
  padding-bottom: 100px;
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`