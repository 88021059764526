import React from "react"
import { Link } from "gatsby-plugin-react-intl"
import styled from "styled-components"
import Img from "gatsby-image"


const BlogHero = ({ post }) => (
    <PostWrapper>
        <HeaderTextGroup>
            <BlogLink to={post.frontmatter.slug}>
                <FeaturedImage fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
            </BlogLink>
            <BlogLink to={post.frontmatter.slug}>
                <h1>{post.frontmatter.title}</h1>
                <Subtitle>
                    {post.frontmatter.date}
                </Subtitle>
                <p>{post.excerpt}</p>
                <AuthorWrapper>
                    <div>
                        <AuthorImage fluid={post.frontmatter.authorImage.childImageSharp.fluid} />
                    </div>
                    <div>
                        <AuthorName>{post.frontmatter.author}</AuthorName>
                        <AuthorTitle>{post.frontmatter.authorTitle}</AuthorTitle>
                    </div>


                </AuthorWrapper>
            </BlogLink>
        </HeaderTextGroup>
    </PostWrapper>
)

export default BlogHero

const AuthorWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: auto 1fr;
`

const Subtitle = styled.span`
  text-size: 12px
  color: ${props => props.theme.color.primary};
`


const AuthorName = styled.span`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  text-size: 12px
`

const AuthorTitle = styled.span`
  color: ${props => props.theme.color.black.light};
  text-size: 12px
`


const PostWrapper = styled.div`
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 40px;
`


const FeaturedImage = styled(Img)`
  padding-bottom: 80px;
  padding-right: 20px;
  border-radius: 25px;
  max-width: 400px;
  min-width: 400px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

const AuthorImage = styled(Img)`
  border-radius: 50%;
  width: 50px;
  margin-right: 20px;
`

const BlogLink = styled(Link)`
  padding-right: 30px;
  text-decoration: none !important;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: auto 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 2px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    color: ${props => props.theme.color.primary};
    margin-bottom: 48px;
  }
`
