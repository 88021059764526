import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-react-intl"

const BlogTile = ({ post }) => {

  return (<PostWrapper>
    <HeaderTextGroup>
      <BlogLink to={post.frontmatter.slug}>
        <FeaturedImage fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />

        <h1>
          {post.frontmatter.title}
        </h1>
        <Subtitle>{post.frontmatter.date}</Subtitle>
        <p>
          {post.excerpt}
        </p>
      </BlogLink>
    </HeaderTextGroup>
  </PostWrapper>
  )
}

export default BlogTile

const Subtitle = styled.span`
  text-size: 12px
`


const PostWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`


const FeaturedImage = styled(Img)`
  padding-bottom: 80px;
  border-radius: 25px;
  max-width: 300px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

const BlogLink = styled(Link)`
  text-decoration: none !important;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 2px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`
